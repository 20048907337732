@import "../theme-variables.scss";

mat-tab-header {
  overflow-x: scroll !important;
}

mat-tab-label-container {
  overflow: visible !important;
}

mat-tab-header::-webkit-scrollbar {
  // TO Remove horizontal scrollbar in tabs
  display: none;
}

mat-tab-header-pagination {
  display: none !important;
}

mat-tab-group.full-height-tab-group {
  height: inherit;
  .mat-tab-body-wrapper {
    height: inherit;
  }
}
