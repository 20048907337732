@import "../theme-variables.scss";

/** DevExpress Data Table Start **/

.dx-theme-generic-typography,
.dx-widget {
  font-family: Tondo, Sans-Serif !important;
}

.grid-container {
  flex: 1 1 auto;
  overflow: auto;
  height: inherit;
  dx-data-grid {
    height: 100%;
  }
}

// -- Table Header

.dx-datagrid-headers {
  font-family: Tondo, Helvetica, Arial, Verdana, Tahoma, Sans-Serif;
  font-size: 12px;
}

dx-data-grid .dx-row td {
  text-align: left;
}

// -- Table Content
.dx-datagrid-content .dx-datagrid-table .dx-row > td,
.dx-datagrid-content .dx-datagrid-table .dx-row > tr > td {
  vertical-align: middle;
  text-align: left !important;

  > div {
    -webkit-touch-callout: default;
  }
}

.dx-datagrid-headers .dx-datagrid-table .dx-row > td {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.22px;
  color: #888888;
}

// -- Pager
.dx-pager .dx-pages .dx-selection {
  border-radius: 15px;
  width: 30px;
  height: 30px;
  padding: 0;
  padding-top: 6px;
  text-align: center;
  background-color: #1b4266;
  color: #fff;
}

.dx-pager .dx-page-sizes .dx-selection {
  border-radius: 15px;
  width: 38px;
  height: 30px;
  padding: 6px 10px 8px;
  background-color: #1b4266;
  color: #fff;
}

.dx-datagrid .dx-column-indicators {
  float: right !important;
}

.dx-scrollable-native.dx-scrollable-native-ios .dx-scrollable-content {
  min-height: 100%;
}

/** DevExpress Data Table End **/

/* Custom Icon in DataTable **/
.cell-icon-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  .cell-icon {
    width: 20px;
    height: 20px;
    display: inline-flex;
    align-items: center;
    margin-left: 14px;
    color: $mine-shaft;
    .mat-icon {
      height: 16px;
      font-size: 16px;
      //margin-bottom: 5px;
    }
    &.orange-icon {
      color: $raw-sienna;
    }
  }
}

.color-cell {
  &.orange-text {
    color: $raw-sienna;
  }
}

.advanced-search.row.row-flex {
  display: flex;
  flex-wrap: wrap;
}
