@import "../theme-variables.scss";

/*
 * Override mat-form style
 */

.form-top {
  padding-top: 10px;
}

.mat-form-field-infix {
  margin: 0 0 8px 16px;
  font-size: 14px;
}

.formly-row {
  height: 90px;
}

.formly-toggle {
  .mat-checked {
    .mat-slide-toggle-bar {
      background-color: rgba(27, 66, 102, 0.54);

      .mat-slide-toggle-thumb-container {
        .mat-slide-toggle-ripple .mat-ripple-element {
          background-color: #1b4266;
        }

        .mat-slide-toggle-thumb {
          background-color: #1b4266;
        }
      }
    }
  }
}

mat-form-field.mat-form-field-invalid {
  animation: bigger;
  animation-duration: 0.3s;
  margin-bottom: 17px;
}

/* Safari only override */
// .mat-input-element:disabled,
// .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
//   -webkit-text-fill-color: #888;
//   opacity: 1; __<<ngThemingMigrationEscapedComment2>>__
// }

/* Safari only override, disabled input field too light */
.mat-input-element:disabled:not(:placeholder-shown),
.mat-select.mat-select-disabled:not(:placeholder-shown) {
  -webkit-text-fill-color: #888;
  opacity: 1; /* required on iOS */
}

// Simple form field style
// No border and smaller size
.simple-field {
  .mat-form-field-infix {
    margin: 0;
  }

  .mat-form-field-underline {
    display: none;
  }

  div.mat-select-arrow::after {
    line-height: inherit;
  }

  .mat-select-value {
    vertical-align: middle;
  }
}

.form-overlay {
  opacity: 0.3;
  cursor: progress;
  mat-form-field {
    pointer-events: none;
  }
  .spinner-container-span {
    position: relative;
    top: 50%;
    left: 50%;
    mat-spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
    }
  }
}

.multiple-select {
  .mat-form-field-flex {
    align-items: center;
  }
}
textarea {
  resize: none !important;
}
