@import "../theme-variables.scss";

/** GLOBAL BUTTON STYLING **/

button:focus {
  outline: none;
}

.mat-button,
.mat-raised-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button,
.mat-fab,
.mat-mini-fab {
  font-family: Tondo, Helvetica, Arial, Verdana, Tahoma, Sans-Serif;
  font-weight: 600;
}

button,
c-form-button {
  &.is-cancel {
    background-color: #e8e8e8;
    color: #888888;
  }

  &.is-reject {
    background-color: #ff2032;
    border: 1px solid #ff2032;
  }
}

c-form-button {
  a:hover {
    text-decoration: none;
  }
}
