@import "../theme-variables.scss";

.badge {
  &.badge-approval-status {
    border: solid 1px #e8e8e8;
    font-weight: bold;
    font-size: 14px;
    padding: 0 15px;
    line-height: 36px;
  }
}

.status-badge {
  border: solid 1px $btn-bg-grey;
  font-size: 14px;
  font-weight: bold;
  line-height: 36px;
  padding: 0 15px;
  text-transform: uppercase;
}

// Data Table Status
.dt-status-badge {
  border-radius: 4px;
  display: inline-block;
  font-size: 10px;
  min-width: 85px;
  padding: 6px;
  text-align: center;
  &.green {
    border: solid 1px #93b371;
    color: #93b371;
  }

  &.grey {
    border: solid 1px #888888;
    color: #888888;
  }

  &.brown {
    border: solid 1px #aa9865;
    color: #aa9865;
  }

  &.red {
    border: solid 1px #ff0000;
    color: #ff0000;
  }

  &.yellow {
    border: solid 1px #de940b;
    color: #de940b;
  }

  &.black {
    border: solid 1px #333333;
    color: #333333;
  }
  &.blue {
    border: solid 1px #1b4266;
    color: #1b4266;
  }
  &.gold {
    border: solid 1px #a38e5c;
    color: #a38e5c;
  }
  &.ford-grey {
    border: solid 1px #979797;
    color: #979797;
  }
  &.grey-40 {
    border: solid 1px #666666;
    color: #666666;
  }
  &.grey-80 {
    border: solid 1px #cccccc;
    color: #cccccc;
  }
}

// Data Table Status
.status-text {
  &.green {
    color: #93b371;
  }

  &.grey {
    color: #888888;
  }

  &.brown {
    color: #aa9865;
  }

  &.red {
    color: #ff0000;
  }

  &.black {
    color: #333333;
  }
}

.badge-text {
  &.green {
    color: #8ab866;
  }

  &.grey {
    color: #888888;
  }

  &.brown {
    color: #aa9865;
  }

  &.red {
    color: #ff2032;
  }

  &.black {
    color: #333333;
  }
}
