@import "../theme-variables.scss";

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Tondo-Signage, Helvetica, Arial, Verdana, Tahoma, Sans-Serif !important;
  color: $dark-slate-blue;
  letter-spacing: normal;
}

h3 {
  &.sub-section {
    font-size: 18px;
  }
}
