/*
 * Global application theme.
 * Framework overrides and customization goes here.
 */

// Shell
@import "src/theme/theme-variables";

.admin-shell-content {
  font-size: 14px;

  > * > mat-card:first-of-type {
    height: calc(100vh - 143px);
  }
}

.mat-body,
.mat-body-1,
.mat-typography {
  font-size: 14px;
}

.footer-tooltip {
  font-size: 12px;
}

label {
  font-family: Tondo, Serif, Sans-serif, cursive, fantasy, Monospace;
  // font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.4px;
  color: rgba(0, 0, 0, 0.6);
}

%view-label {
  width: 100%;
  font-size: 12px;
  color: #888;
  line-height: 1.33;
}

.view-label {
  @extend %view-label;
}

.view-label-blue {
  @extend %view-label;
  color: #1b4266;
}

.mat-checkbox-layout {
  white-space: normal !important;
}

// Temp Use
.mat-checkbox-inner-container {
  margin-top: 5px !important;
}

.mat-tab-label-container {
  background-color: white;
  .mat-tab-label {
    opacity: 1;
    user-select: none;
    .mat-tab-label-content {
      color: #41434c;
    }

    &.mat-tab-label-active {
      .mat-tab-label-content {
        color: #333;
        font-weight: 700;
      }
    }
  }
}

.mat-expansion-panel-header-title {
  font-size: 14px;
  font-weight: bold;
  color: #333;
}

.mat-expansion-panel-header.mat-expanded {
  .mat-expansion-panel-header-title {
    color: #1b4266;
  }
}

.action-menu {
  .mat-menu-item.action-button {
    height: 30px;
    line-height: 30px;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.87);
    &:disabled {
      pointer-events: none;
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}

@media (min-width: $tablet-portait-width) and (max-width: 1366px) {
  .action-menu {
    .mat-menu-item.action-button {
      line-height: 48px;
      height: 48px;
      font-size: 14px;
    }
  }
}

.main-container {
  margin-left: 0;
  max-width: 980px;
}

.main-container.is-support-widescreen {
  max-width: 1530px;
}

.mat-menu-content {
  button {
    height: 30px;
    line-height: 30px;
  }

  a[disabled="true"] {
    opacity: 0.5;
  }
}

.content-section-container {
  max-width: 520px;
  width: calc(100% - 170px);
  color: #333;
}

.side-section-container {
  flex-grow: 1;
}

mat-card.action-footer {
  height: 100px;
  position: sticky;
  bottom: 0;
  background: white;
  z-index: 10;

  .action-footer-container {
    padding-top: 25px;
    // max-width: 980px;
    padding-right: 15px;
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #e8e8e8;

    button {
      margin-left: 15px;
      min-width: 120px;
    }
  }
}

.button-container > c-form-button {
  margin-left: 10px;
}

.button-container > button {
  margin-left: 10px;
}

.mat-tab-label.mat-tab-disabled {
  cursor: not-allowed !important;
}

c-sync-form-uploader.ng-invalid.ng-touched {
  .upload-block {
    border: 2px solid #f44336;
  }
}

.dx-overlay-wrapper.dx-loadpanel-wrapper {
  z-index: 98 !important;

  .dx-overlay-content.dx-resizable.dx-loadpanel-content {
    box-shadow: none;
    border: none;

    .dx-loadindicator-wrapper.dx-loadindicator-image {
      background-size: 350%;
      filter: grayscale(100%);
    }
  }
}

.mat-loading .mat-button-wrapper {
  visibility: hidden;
}

.mat-loading .mat-progress-spinner {
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 10px);

  circle {
    stroke: white;
  }
}

mat-expansion-panel.has-delete-button {
  .mat-expansion-indicator {
    margin-right: 25px;
    margin-bottom: 6px;
    transform-origin: 50% 65%;

    ~ mat-icon {
      width: 14px;
      height: 14px;
      font-size: 18px;
      line-height: 14px;
    }
  }
}

.no-data-button {
  width: 123px;
  height: 36px;
  display: inline-block !important;
  position: absolute !important;
  left: 45%;
  top: 50%;
  z-index: 99;
}

.loyalty-button-menu {
  position: absolute;
  top: 5px;
  left: 0;
  width: 260px;
  min-height: 0;
  // height: 100%;
  max-height: 60vh !important;
}

.no-padding {
  padding: 0;
}

.no-margin {
  margin: 0;
}

.no-table-data {
  color: #999;
  font-size: 17px;
  text-align: center;
}

div.mat-select-arrow {
  width: auto;
  // height: auto;
  border: none;

  &:after {
    font-family: "Material Icons", Sans-Serif;
    content: "keyboard_arrow_down";
    font-size: 24px;
    line-height: 0;
  }
}

.mat-error,
.mat-hint {
  padding-left: 16px;
  font-size: 10px;
  &.reminder {
    color: #d06d45;
  }
}

@keyframes bigger {
  from {
    margin-bottom: 0;
  }
  to {
    margin-bottom: 17px;
  }
}

.add-three-dots {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Quick fix for ng-select padding
.ng-select .ng-select-container .ng-value-container .ng-input > input {
  padding: 0;
}

.wide-radio-label {
  padding-left: 44px;
}

.brownish-grey {
  color: $brownish-grey;
}

.mat-select-wrap {
  .mat-select .ng-star-inserted {
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.hide-tab-1 > mat-tab-header .mat-tab-labels div.mat-tab-label:nth-of-type(1),
.hide-tab-2 > mat-tab-header .mat-tab-labels div.mat-tab-label:nth-of-type(2),
.hide-tab-3 > mat-tab-header .mat-tab-labels div.mat-tab-label:nth-of-type(3),
.hide-tab-4 > mat-tab-header .mat-tab-labels div.mat-tab-label:nth-of-type(4),
.hide-tab-5 > mat-tab-header .mat-tab-labels div.mat-tab-label:nth-of-type(5),
.hide-tab-6 > mat-tab-header .mat-tab-labels div.mat-tab-label:nth-of-type(6),
.hide-tab-7 > mat-tab-header .mat-tab-labels div.mat-tab-label:nth-of-type(7),
.hide-tab-8 > mat-tab-header .mat-tab-labels div.mat-tab-label:nth-of-type(8),
.hide-tab-9 > mat-tab-header .mat-tab-labels div.mat-tab-label:nth-of-type(9),
.hide-tab-9 > mat-tab-header .mat-tab-labels div.mat-tab-label:nth-of-type(10) {
  display: none;
}

mat-form-field.flex-input .mat-form-field-infix {
  display: flex;
}

// Import custom utils for
@import "./utils/badge";
@import "./utils/buttons";
@import "./utils/cards";
@import "./utils/desclist";
@import "./utils/dialogs";
@import "./utils/general";
@import "./utils/input";
@import "./utils/pages";
@import "./utils/datatable";
@import "./utils/ng_select_mat_form_field";
@import "./utils/mat-tab";
@import "./utils/form";
