@import "../theme-variables.scss";

.main-wrapper {
  background-color: #fff;
  min-height: 100%;
  .form-container {
    &.is-full-height {
      height: inherit;
      min-height: calc(100vh - 228px);
    }
  }
  &.has-datatable {
    display: flex;
    height: 100%;
    flex-direction: column;
    // Override Card Container
    .card-container {
      display: flex;
      flex-direction: column;
    }
  }
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

.float-right {
  * {
    float: right;
  }
}

.collapse-panel-header {
  font-size: 14px;
  font-weight: bold;
  color: $dark-slate-blue;
}
