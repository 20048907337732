@import "../theme-variables.scss";

.form-group {
  .form-control:focus {
    border-color: $dark-slate-blue;
    box-shadow: none;
  }
  label {
    margin-bottom: 15px;
    font-size: 12px;
    color: $dark-slate-blue;
  }
}
