@import "../theme-variables.scss";

// Main content card
.card-container {
  padding: 15px;
  height: inherit;
  &.is-gapless {
    margin: 0;
    padding: 0;
  }
  &.lg-padding {
    padding: 28px;
  }
}

.search-card-title {
  font-family: Tondo-Signage, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  font-size: 16px;
  color: $dark-slate-blue;
  &.table-header {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.spl-card {
  border-radius: 3px;
  &.has-border {
    &.grey {
      border: solid 1px $btn-bg-grey;
    }
  }
}

.offer-card-row {
  margin-bottom: 15px;
}

.flex-card-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  .card-container {
    display: inline-block !important;
    height: auto;
  }
}

.result-container,
.result-listing {
  .full-width-card {
    width: 100% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  virtual-scroller {
    .full-width-card {
      width: 100% !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}
