@import "../theme-variables.scss";

c-desc-list {
  span {
    &.member-tier {
      color: #808080;
      font-size: 20px;
      font-weight: bold;
    }
    &.member-name {
      color: #333;
      font-size: 20px;
      font-weight: bold;
    }
    &.medium-bold {
      color: #333;
      font-size: 16px;
      font-weight: bold;
    }
    &.label-icon {
      line-height: 25px;
    }
  }
}
