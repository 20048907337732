@font-face {
  font-family: Tondo;
  src: url("/assets/fonts/Tondo_Std_Rg.ttf") format("truetype");
}
@font-face {
  font-family: Tondo;
  src: url("/assets/fonts/Tondo_Std_Bd.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: Tondo-Signage;
  src: url("/assets/fonts/Tondo-Signage.ttf") format("truetype");
}

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/MaterialIcons-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/MaterialIcons-Symbols-Outlined.woff2") format("woff2");
}

.material-icons {
  font-family: "Material Icons", Sans-Serif;
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: "liga";
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined", Sans-Serif;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
}
